import { ENTRIES_VARIABLE_ACTIONS as actions } from '../actions'
import { getNewEntity, findById, sort } from './helpers'

const diagnoseConstructorReducer = (state = [], { type, payload }) => {
  let newState = [...state]
  //let item = false
  //if (payload && payload.id) {
  //item = findById(newState, payload.id)
  //}

  switch (type) {
    case actions.LOAD :
      return payload || newState

    case actions.UPDATE:
      //default
      return newState

    case actions.RESTORE :
      newState.push(payload.item)
      newState = sort(newState)

      return newState

    case actions.ADD :
      let newEntity = getNewEntity(newState, payload)
      newEntity.value = payload.data.data.value
      newEntity.title = payload.data.data.title
      newState.push(newEntity)

      return newState
    case actions.CHANGE :
      return newState

    case actions.DELETE :
      newState = [...state].filter(
        (item) => item.id !== parseInt(payload),
      )

      return newState

    default:
      return state
  }
}

export default diagnoseConstructorReducer
