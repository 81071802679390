import { EntityWithDefaultHtml } from '@/plugins/dynamic_forms/entities/items/EntityWithDefaultHtml'

class List extends EntityWithDefaultHtml {
  edit () {
    this.container.get('list').show(this)
  }

  elementHead (showTypes) {
    let head = false
    if (showTypes) {
      head = this.data.defaulValue || this.data.title
    } else {
      head = this.data.name
    }
    this.element.innerHTML = head
  }

  /**
   * inherit
   * redefine parent method
   */
  get value () {
    const value = this.data.defaultValue || this.defaultHtml

    return this.formatValue(value)
  }

  /**
   * @inheritdoc
   */
  get isManualInput () {
    return this.type === 'ext_list'
  }
}
export default List
