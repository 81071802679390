import Entity from './index'
import { el, mount } from 'redom'
// import { DeleteEntity } from '../../events'
import { DIAGNOSE_ACTIONS } from '@/plugins/dynamic_forms/actions'

class Diagnose extends Entity {
  static buildTemplate (data, options) {
    let template = super.buildTemplate(data, options)
    template += '<br>'

    return template
  }

  //create html component entity
  static createElement (data, options) {
    const template = this.buildTemplate(data, options)
    $(template).insertBefore(data.emitter)
  }

  constructor (id, element, options, container = false) {
    const elementHtml = element.innerHTML
    super(id, element, options, container)

    if (elementHtml !== Entity.defaultHtml) {
      this.defaultHtml = elementHtml
    }
  }

  edit () {
    console.debug('herabora')
  }

  /**
   * redefined
   */
  update (formData) {
    if (!formData) {
      return
    }
    this.store.dispatch({
      type: this.actions.UPDATE,
      payload: {
        id: this.id,
        data: formData,
      },
    })
    this._updateElement()
  }

  showValue () {
    this._updateElement()
  }

  _updateElement () {
    this.contextMenu = this.container.get('contextMenu')
    const value = this.data.value
    const first = value.first ? t('set_first') : null
    const chronical = value.chronical ? ` ${t('chronical')}` : null
    const prof = value.prof ? ` ${t('professional')}` : null
    const item = this.data.item
    const props = [first, chronical, prof].filter((prop) => prop != null)
    const propsString = props.length ? `/ ${props.join(' / ')} /` : ''

    const text = `
      <span class="entry_template_diagnosis_code">${item.code_string}</span>: ${item.title} 
      <i class="entry_template_diagnosis_props">${propsString}</i>
      <i>${this.value.text || ''}</i>
    `
    this.element.innerHTML = text
    this.element.setAttribute('contenteditable', false)
    const editBtn = el('i.tree-icon.diagnosis-btn.fa-border.fa.fa-pencil.control')
    const deleteBtn = el('i.tree-icon.diagnosis-btn.fa-border.fad.fa-trash-alt.control')
    deleteBtn.onclick = () => {
      bootbox.confirmYN(`${t('delete')}?`, (res) => {
        if (res) {
          const data = this.data
          const nested = $('#nested-diagnoses .disease_id').filter(function () {
            return parseInt(this.value) === data.item.id
          })
          nested.parents('.diagnosis_item_data').find('.destroy-field').addClass('destroy-item')

          // Специально оставляю это для ориентирования
          // После завершения заполнения происходит очистка ненужных полей
          // Я сделал так, чтобы эта очистка происходила сразу
          // const dispatcher = this.container.get('dispatcher')
          // const deleteEntity = new DeleteEntity(this.element)
          // dispatcher.dispatch(deleteEntity)

          // удаление элемента из редактора
          $(this.element).remove()
          // удаление инпута с айдишником
          nested.find('.destroy-item').remove()

          const store = this.container._components.store
          store.dispatch({
            type: DIAGNOSE_ACTIONS.DELETE,
            payload: data.id,
          })
        }
      })
    }

    editBtn.onclick = this.editDiagnose.bind(this)
    editBtn.style.marginLeft = '3px'
    deleteBtn.style.marginLeft = '3px'

    mount(this.element, deleteBtn)
  }

  /**
   * inherit
   */

  /**
   * run context menu
   */
  editDiagnose () {
    event.preventDefault()
    this.contextMenu.build(tinymce.activeEditor, event, this.element)

    return false
  }

  elementHead (showTypes) {
    let head = false
    if (showTypes) {
      head = `${this.data.name}: ${this.data.item.title}`
    } else {
      head = this.data.name
    }
    this.element.innerHTML = head
  }

  get switch () {
    return false
  }
}
export default Diagnose
