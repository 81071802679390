import EntryEntity from '@/plugins/dynamic_forms/entities/items/entry_entity'
import Entity from '@/plugins/dynamic_forms/entities/items/index'
import { stringSanitize } from '@/plugins/dynamic_forms/reducers/helpers'

export class EntryEntityWithDefaultHtml extends EntryEntity {
  constructor (id, element, options, container = false) {
    const elementHtml = stringSanitize(element.innerHTML)
    const elementDataName = element.dataset.name
    const isNotEntityDefaultHtml = elementHtml !== Entity.defaultHtml
    const isNotElementDataName = elementHtml !== elementDataName

    super(id, element, options, container)

    if (isNotEntityDefaultHtml && isNotElementDataName) {
      this.defaultHtml = elementHtml
    }
  }
}
