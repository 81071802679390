export const SET_EDITOR_OPENED = 'set_editor_opened'
export const EDITOR_OPENED = 'editorOpened'

const editorOpened = (state = false, { type, payload }) => {
  switch (type) {
    case SET_EDITOR_OPENED:
      return true
    default:
      return state
  }
}

export default editorOpened
