import { stringSanitize } from '@/plugins/dynamic_forms/reducers/helpers'
import Entity from '@/plugins/dynamic_forms/entities/items/index'

export class EntityWithDefaultHtml extends Entity {
  constructor (id, element, options, container = false) {
    const elementHtml = stringSanitize(element.innerHTML)
    const elementDataName = element.dataset.name
    const isNotEntityDefaultHtml = elementHtml !== Entity.defaultHtml
    const isNotElementDataName = elementHtml !== elementDataName

    super(id, element, options, container)

    if (isNotEntityDefaultHtml && isNotElementDataName) {
      this.defaultHtml = elementHtml
    }
  }
}
