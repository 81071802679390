import { PRINT_AREA } from '../configuration/reducer_types'
import PrintArea from './items/print_area'
import { PRINT_AREA_ACTIONS as actions } from '../actions'

export default class PrintAreaManager {
  constructor (container) {
    this.container = container
    this.store = container.get('store')
  }

  get items () {
    return this.store.getState()[PRINT_AREA]
  }

  static checkPosition (content) {
    if (content && content.includes('print_area')) {
      const id = content.match(/data-id="(\d+)"/)[1]
      const area = new PrintArea({ id })
      area.checkPosition()
    }
  }

  static hasIllegalAreas () {
    const content = tinymce.activeEditor?.getContent()
    if (!content) { return false }

    return content.includes('wrong_position')
  }

  deleteItemsByContent (content) {
    let match
    const ids = []
    const regex = /<p[^>]*print_area_(start|end)[^>]*data-id="(\d+)"[^>]*>.*?<\/p>/g
    while ((match = regex.exec(content)) !== null) {
      const id = parseInt(match[2])
      if (!ids.includes(id)) {
        this.destroy({ id })
        ids.push(id)
      }
    }
  }

  create (item) {
    this.store.dispatch({
      type: actions.ADD,
      payload: item,
    })
    const element = new PrintArea()
    element.create(item)
  }

  destroy (item) {
    this.store.dispatch({
      type: actions.DELETE,
      payload: item,
    })
    const element = new PrintArea(item)
    element.destroy()
  }

  update (item) {
    this.store.dispatch({
      type: actions.UPDATE,
      payload: item,
    })
    const element = new PrintArea(item)
    element.update()
  }

  _findById (id) {
    return this.items.find((item) => item.id === id)
  }

  nextAvailableID () {
    const ids = this.items.map((item) => item.id)
    let result
    let index = 1
    while (!result) {
      ids.includes(index) ? index++ : result = index
    }

    return result
  }
}
