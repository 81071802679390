import { createStore } from 'redux'
import { last } from 'ramda'
import Container from 'container'
import reducersStorage from '../reducers'
import { UpdateFormulasEvent } from '../events'

import {
  CALCULATE,
  COMPLAINTS,
  CONCLUSIONS,
  DESTINATIONS,
  DIAGNOSE,
  DIAGNOSES_CONSTRUCTOR,
  ENTRIES_VARIABLE,
  EXTENSIBLE_LIST,
  FIX_LIST,
  PRINT_AREA,
  SEMD_AREA,
  SEMD_ENTITY,
  VARIABLES,
} from '../configuration/reducer_types'

import { mainCofig } from '../configuration/main_config'
import { SEMD_ENTITIES_ACTIONS } from '@/vue_apps/Protocols/SemdProtocolEditor/reducers/semdEntitiesActions'

export default class EntityManager {
  /**
   * withiout container in plugggins
   */
  constructor (container = false) {
    this.config = mainCofig
    this.container = container
    if (container) {
      this.store = container.get('store')
      this.dispatcher = container.get('dispatcher')
    }
    this.deletedEntities = []
  }

  setContainer (container) {
    this.container = container
  }

  /**
   * buld with html and store(with factory help)
   */
  build (type, data = false) {
    const factory = this.container.get('entity_factory')
    const elementData = factory.build(type, data)
    const entity = this.getEntityByData(elementData)
    if (entity) {
      entity.afterCreate()

      return entity
    }

    return false
  }

  /**
   * create only entity object
   */
  create (id, element, type) {
    if (type === SEMD_ENTITY || type === SEMD_AREA) { return }

    const option = this.config[type]
    // eslint-disable-next-line new-cap
    const entity = new option.entityClass(id, element, option, this.container)

    return entity
  }

  merge (item) {
    const addData = item.template_data ? JSON.parse(item.template_data) : ''
    const mokeContainer = new Container()
    const mergeStore = createStore(reducersStorage)
    mokeContainer.add('store', mergeStore)
    const helpManager = new this.constructor(mokeContainer)
    helpManager.searchContainer = $('<div>' + item.template_html + '</div>')[0]

    helpManager.load(addData)
    const calculates = []
    Array.prototype.forEach.call(
      helpManager.entitiesCollection,
      (item) => {
        const entity = helpManager.getEntity(item)
        if (entity.type === CALCULATE) {
          calculates.push(entity)
        } else {
          const addEntity = this.updateEntityForMerge(entity)
          entity.updateElement(addEntity)
          if (entity.type === VARIABLES) {
            this.dispatcher.dispatch(new UpdateFormulasEvent(
              entity, addEntity, helpManager)
            )
          }
        }
      }
    )
    if (calculates.length > 0) {
      calculates.forEach((entity) => {
        entity.updateElement(this.updateEntityForMerge(entity))
      })
    }
    this.elementsContainer.append(helpManager.searchContainer)
    $('#template_record-list-modal').megamodal('hide')
  }

  updateEntityForMerge (entity) {
    const options = entity.options
    this.store.dispatch({
      type: entity.actions.ADD,
      payload: {
        title: options.title,
        alias: options.alias,
        data: { data: entity.data },
      },
    })
    const addedEntity = last(this.store.getState()[entity.type])

    return addedEntity
  }

  ///=========================

  /**
   * find entity by store data
   */
  getEntityByData (data) {
    const elements =
      tinymce
        .activeEditor.dom
        .select(`[data-name=${data.name}]`)
    if (!elements || elements.length <= 0) {
      return false
    }

    return this.getEntity(elements[0])
  }

  /**
   * @param element - html elment
   * @return entity: entity
   */
  getEntity (element) {
    let entity = false
    const type = this.getElementType(element)
    if (!type) {
      return false
    }

    const elementID = element.getAttribute('data-id')
    entity = this.create(elementID, element, type)

    return entity
  }

  /**
   * collection method
   * @update entities
   * update entities values
   */
  updateEntities () {
    const collection = this.entitiesCollection
    collection.forEach((element) => {
      const entity = this.getEntity(element)
      if (entity) entity.update()
    })
  }

  /**
   * collection method
   * @update entities
   * update entities values
   */
  setEvents () {
    const collection = this.entitiesCollection
    collection.forEach((element) => {
      const entity = this.getEntity(element)
      entity.setEvents()
    })
  }

  /**
   * @return :void
   * @param booleanshowTile selected flag
   */
  setEntitiesHead (showTitle) {
    const collection = this.entitiesCollection
    collection.forEach((element) => {
      const entity = this.getEntity(element)
      if (!showTitle) {
        this._formatOldType(entity.element, entity.data)
      }
      if (entity) entity.elementHead(showTitle)
    })
  }

  /**
   * collection method
   * return entities collection
   */
  showValues () {
    const collection = this.entitiesCollection
    collection.forEach((element) => {
      const entity = this.getEntity(element)
      if (entity) entity.showValue()
    })
  }

  /**
   * :html elements collection
   */

  // remove all broken variables without data
  removeBrokenElements () {
    if (!this.elementsContainer || !this.store) return
    const allTElements = this.elementsContainer.querySelectorAll('.t-element')
    const data = this.store.getState()
    allTElements.forEach((element) => {
      const elemDataType = element.dataset.type
      const entitiesListWithElemType = data[elemDataType] || []
      const entity = entitiesListWithElemType.find((item) => item.id === parseInt(element.dataset.id))
      if (!entity) {
        const isPrintArea = element.classList.contains('print_area_start') ||
          element.classList.contains('print_area_end')
        const IsEntryVariable = element.classList.contains('entries_variable')

        if (element.classList.contains(SEMD_ENTITY)) { return }
        if (element.classList.contains(SEMD_AREA)) { return }

        const IsEntryVariableWithNoData = IsEntryVariable &&
          (!data.entries_variable || (data.entries_variable && !data.entries_variable.find((i) => i.id === parseInt(element.dataset.id))))

        if ((!isPrintArea && !IsEntryVariable) || (IsEntryVariable && IsEntryVariableWithNoData)) element.remove()
      }
    })
  }

  get entitiesCollection () {
    const baseCollection = this.elementsContainer
      .querySelectorAll('.t-element')

    return baseCollection
  }

  get entiies () {
    const collection = []
    this.entitiesCollection.forEach((item) => collection.push(item))

    return collection
  }

  /**
   *
   */
  get elementsContainer () {
    if (this.searchContainer) {
      return this.searchContainer
    }

    return tinymce.activeEditor.getBody()
  }

  /**
   *
   */
  findElements (searchContainer) {
    return searchContainer.querySelectorAll('.t-element')
  }

  /**
   * @return :void
   * @params data - entity data
   * load data in storage
   */

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static load (data, store) {
    store.dispatch({
      type: mainCofig[VARIABLES].actions.LOAD,
      payload: data.variables,
    })
    store.dispatch({
      type: mainCofig[CALCULATE].actions.LOAD,
      payload: data.calculate,
    })
    store.dispatch({
      type: mainCofig[FIX_LIST].actions.LOAD,
      payload: data.fix_list,
    })
    store.dispatch({
      type: mainCofig[EXTENSIBLE_LIST].actions.LOAD,
      payload: data.extensible_list || data.ext_list,
    })
    store.dispatch({
      type: mainCofig[CONCLUSIONS].actions.LOAD,
      payload: data.conclusions || [],
    })
    store.dispatch({
      type: mainCofig[COMPLAINTS].actions.LOAD,
      payload: data.complaints || [],
    })
    store.dispatch({
      type: mainCofig[DESTINATIONS].actions.LOAD,
      payload: data.destinations || [],
    })
    store.dispatch({
      type: mainCofig[DIAGNOSE].actions.LOAD,
      payload: data.diagnoses || [],
    })
    store.dispatch({
      type: mainCofig[DIAGNOSES_CONSTRUCTOR].actions.LOAD,
      payload: data.diagnoses_constructor || [],
    })
    store.dispatch({
      type: mainCofig[ENTRIES_VARIABLE].actions.LOAD,
      payload: data.entries_variable || [],
    })
    store.dispatch({
      type: mainCofig[PRINT_AREA].actions.LOAD,
      payload: data.print_area || [],
    })
    store.dispatch({
      type: SEMD_ENTITIES_ACTIONS.LOAD,
      payload: {},
    })
  }

  load (data) {
    this.constructor.load(data, this.store)
  }

  /**
   * delete deleted entities in store
   */
  clearStore () {
    this.deletedEntities.forEach((entity) => {
      this.store.dispatch({
        type: entity.actions.DELETE,
        payload: entity.id,
      })
    })
    this.deletedEntities = []
  }

  removeElement (entity) {
    console.debug(entity)
    entity.element.remove()
    this.deletedEntities.push(entity)
  }

  /**
   * define private interface
   *
   */

  // dispute methods
  getElementType (element) {
    if (element.classList.contains(VARIABLES)) return VARIABLES
    if (element.classList.contains(FIX_LIST)) return FIX_LIST
    if (element.classList.contains(EXTENSIBLE_LIST)) return EXTENSIBLE_LIST
    if (element.classList.contains(CALCULATE)) return CALCULATE
    if (element.classList.contains(CONCLUSIONS)) return CONCLUSIONS
    if (element.classList.contains(COMPLAINTS)) return COMPLAINTS
    if (element.classList.contains(DESTINATIONS)) return DESTINATIONS
    if (element.classList.contains(DIAGNOSE)) return DIAGNOSE
    if (element.classList.contains(DIAGNOSES_CONSTRUCTOR)) {
      return DIAGNOSES_CONSTRUCTOR
    }
    if (element.classList.contains(PRINT_AREA)) return PRINT_AREA
    if (element.classList.contains(ENTRIES_VARIABLE)) return ENTRIES_VARIABLE
    if (element.classList.contains(SEMD_ENTITY)) return SEMD_ENTITY
    if (element.classList.contains(SEMD_AREA)) return SEMD_AREA

    return false
  }

  /**
   * new from formats old methods name
   */
  _formatOldType (elem, data) {
    if (elem) {
      if (data.name.indexOf('V') !== -1) {
        data.name = data.name.replace('V', 'X')
        elem.setAttribute('data-name', data.name)
      }
      if (data.name.indexOf('M') !== -1) {
        data.name = data.name.replace('M', 'F')
        elem.setAttribute('data-name', data.name)
      }
      if (data.name.indexOf('EL') !== -1) {
        data.name = data.name.replace('EL', 'Open')
        elem.setAttribute('data-name', data.name)
      }
      if (data.name.indexOf('FL') !== -1) {
        data.name = data.name.replace('FL', 'Fix')
        elem.setAttribute('data-name', data.name)
      }
      if (data.name.indexOf('NaN') !== -1) {
        const elementId = elem.getAttribute('data-id')
        if (elementId) {
          data.name = data.name.replace('NaN', elementId)
          elem.setAttribute('data-name', data.name)
        }
      }
    }
  }
}
