import { applicationConf } from '../../configuration/config'

class Entity {
  //STATIC METHODS
  static defaultHtml = String.fromCharCode(160)

  static buildTemplate (data, options) {
    return `<span data-id=${data.id} data-name="${data.name}" data-type="${options.dataType}" class="${options.class} mceNonEditable t-element">${data.name}</span>`
  }

  //create html component entity
  static createElement (data, options) {
    const template = this.buildTemplate(data, options)
    tinymce.activeEditor.insertContent(template)
  }

  constructor (id, element, options, container = false) {
    this.id = parseInt(id)
    this.element = element
    this.type = options.dataType
    this.options = options
    this.actions = options.actions

    //for replace
    this.defaultHtml = Entity.defaultHtml // &nbsp; (кладётся при пустом редакторе, иначе он схлопнется)
    // maybe false
    this.container = container
    if (this.container) {
      this.store = this.container.get('store') || false
    }
  }

  /**
   * :void
   * update element
   */
  update () {
    const newValue = this.formatHtml()

    if (!newValue && newValue !== 0) {
      return false
    }

    this.store.dispatch({
      type: this.actions.UPDATE,
      payload: {
        id: this.id,
        value: newValue,
      },
    })
  }

  /**
   * operation reverse update
   */
  updateElement (data) {
    this.element.setAttribute('data-id', data.id)
    this.element.setAttribute('data-name', data.name)
    this.elementHtml = data.name
  }

  formatHtml () {
    return this.elementHtml.replace('&nbsp;', '')
  }

  setEvents () {
    return false
  }

  /**
   * show entity value on frontentd
   */
  showValue () {
    this.elementHtml = this.value
  }

  /**
   * @return boolean
   * check entity on ident
   */
  isIdent (element) {
    return this.element === element
  }

  set elementHtml (html) {
    // check html
    this.element.innerHTML = html
  }

  get elementHtml () {
    return this.element.innerHTML
  }

  get value () {
    const value = (
      this.data.defaultValue ||
      this.data.value ||
      this.defaultHtml
    )

    return this.formatValue(value)
  }

  /**
   * check value on empty ent format this
   */
  formatValue (value) {
    if (value && value.length === 1 && value.charCodeAt(0) === 65279) {
      value = this.defaultHtml
    }

    return value
  }

  /**
   * check have entity context menu
   */
  get contexted () {
    let contexted = false
    applicationConf.listClass.split(',').forEach((className) => {
      if ([...this.element.classList].contains(className)) {
        contexted = true
      }
    })

    return contexted
  }

  get isManualInput () {
    return false
  }

  get saveCollbak () {
    return false
  }

  //switch on tab key
  get switch () {
    return true
  }

  get data () {
    if (!this.store) {
      return false
    }
    let data = false
    data = this.store.getState()[this.type].find(
      (item) => item.id === parseInt(this.id)
    )

    return data
  }

  /**
   * abstract method
   */
  afterCreate () {}
}
export default Entity
