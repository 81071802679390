export class InsertDynamicEntity {
  constructor (value, element) {
    this.value = value
    this.element = element
  }

  getListener () {
    return 'insert_dynamic_entity'
  }
}

export class InsertFixEntity {
  constructor (value, element) {
    this.value = value
    this.element = element
  }

  getListener () {
    return 'insert_fix_entity'
  }
}

export class DeleteEntity {
  constructor (element) {
    this.element = element
  }

  getListener () {
    return 'delete_entity'
  }
}

export class ShowPanelEvent {
  constructor (element) {
    this.element = element
  }

  getListener () {
    return 'show_panel'
  }
}
export class UpdateFormulasEvent {
  constructor (oldData, newData, manager) {
    this.oldData = oldData
    this.newData = newData
    this.manager = manager
  }

  getListener () {
    return 'update_formulas'
  }
}

export class BuildEntryVaiableModalEvent {
  getListener () {
    return 'build_entry_variable_modal'
  }
}

export class BuildPrintAreaModalEvent {
  getListener () {
    return 'build_print_area_modal'
  }
}
