const WRONG_POSITION_CLASS_NAME = 'wrong_position'
export default class PrintArea {
  constructor (item) {
    this.types = {
      START: 'start',
      END: 'end',
    }
    this.dom = tinymce.activeEditor.dom
    if (item) {
      this._element = this._findElement(item)
      this._item = item
    }
  }

  _findElement (item = {}) {
    return {
      start: this.dom.select(`[data-id="${item.id}"].print_area_start`)[0],
      end: this.dom.select(`[data-id="${item.id}"].print_area_end `)[0],
    }
  }

  _elementCheck () {
    return (this._element.start && this._element.end)
  }

  destroy () {
    if (this._elementCheck()) {
      this._element.start.remove()
      this._element.end.remove()
    }
  }

  update () {
    if (this._elementCheck()) {
      this._element.start.textContent = this._item.title + ` (${t('start')})`
      this._element.end.textContent = this._item.title + ` (${t('end')})`
    }

    return this._element
  }

  create (item) {
    this._item = item
    this._element = this._createElement()

    return this._insertElement(this._element)
  }

  set illegal (flag) {
    if (this._elementCheck()) {
      this._toggleElementWrongPosClass(this._element.start, flag)
      this._toggleElementWrongPosClass(this._element.end, flag)
    }
  }

  _toggleElementWrongPosClass (element, flag) {
    const classList = element.classList
    const contains = classList.contains(WRONG_POSITION_CLASS_NAME)
    if (contains && !flag) {
      classList.remove(WRONG_POSITION_CLASS_NAME)
    } else if (!contains && flag) {
      classList.add(WRONG_POSITION_CLASS_NAME)
    }
  }

  checkPosition () {
    if (this._elementCheck()) {
      const startPosition = this._element.start.offsetTop
      const endPosition = this._element.end.offsetTop
      this.illegal = (startPosition > endPosition)
    }
  }

  _createElement () {
    return {
      start: this.dom.create('p', this._getElementAttrs(this.types.START), this._item.title),
      end: this.dom.create('p', this._getElementAttrs(this.types.END), this._item.title),
    }
  }

  _getElementAttrs (type) {
    return {
      class: `mceNonEditable t-element hidden-print print_area_${type}`,
      contenteditable: false,
      'data-id': this._item.id,
    }
  }

  _insertElement (element) {
    this.dom.add(this.dom.getRoot(), element.start)
    this.dom.add(this.dom.getRoot(), element.end)

    return element
  }
}
