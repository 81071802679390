import Entity from './index'

export default class DiagnoseConstructor extends Entity {
  edit () {
    const modal = this.container.get('mkb_modal')
    const selectedCategories =
      this.container.get('entity_manager')
        .getEntity(this.element).data.categories_ids
    this.container.get('api').diseases()
      .then((tree) => { modal.mount(tree, selectedCategories, false, this.element) })
      .then(() => modal.show())
  }

  /**
   * redefined
   */
  update (formData) {
    if (!formData) {
      return
    }
    this.store.dispatch({
      type: this.actions.UPDATE,
      payload: {
        id: this.id,
        data: formData,
      },
    })
    this._updateElement()
  }

  showValue () {
    this._updateElement()
    this.setEvents()
  }

  setEvents () {
    this.element.oncontextmenu = this.selectDiagnosesModal.bind(this)
    this.element.onclick = this.selectDiagnosesModal.bind(this)
  }

  _updateElement () {
    this.element.innerHTML = t('choose_diagnosis')
    this.element.setAttribute('contenteditable', false)
  }

  afterCreate () {
    if (this.container.get('editor').mode === 'edit') this.showValue()
  }

  selectDiagnosesModal () {
    const modal = this.container.get('select_mkb_modal')
    this.container.get('api')
      .getAssignDiagnosesCat(this.data.categories_ids)
      .then((response) => modal.mount(response, this.element))
      .then(() => modal.show())
  }

  elementHead (showTypes) {
    let head = false
    if (showTypes) {
      head = this.data.title
    } else {
      head = this.data.name
    }
    this.element.innerHTML = head
  }

  /**
   * inherit
   */
  get switch () {
    return false
  }

  static buildNode (data, options) {
    const attrs = {
      'data-id': data.id,
      'data-name': data.name,
      'data-type': options.dataType,
      class: `${options.class} mceNonEditable t-element`,
    }

    return tinymce.activeEditor.dom.create('span', attrs, data.name)
  }

  // @override
  static createElement (data, options) {
    try {
      const newNode = this.buildNode(data, options)
      const rng = data.caretRng
      rng.insertNode(newNode)
    } catch (e) {
      super.createElement(data, options)
    }
  }
}
