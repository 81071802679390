const TEXT_NODE_TYPE = 3
export const checkDynamicElement = (node) => {
  if (!node || node.nodeType === TEXT_NODE_TYPE) return false
  if (node.classList && node.classList.contains('t-element')
  ) return true

  return false
}

export const getDynamicElements = (node) => {
  if (!node || node.nodeType !== 1) return false
  const elements = node.querySelectorAll('t-element')
  if (elements.length > 0) {
    return elements
  }

  return false
}
