import { last, prop } from 'ramda'

export const getNewEntity = (state, payload) => {
  let value = ''
  let lastElement = prop('id', last(state))
  let index = lastElement ? (lastElement + 1) : 1
  let newEntity = {
    id: index,
    name: `${payload.alias}${index}`,
    value,
    title: payload.title,
  }

  return newEntity
}

export const findById = (store, id) => {
  let item = store.find((item) => parseInt(item.id) === parseInt(id))
  if (item) {
    return item
  }

  return false
}

export const sort = (state) => {
  state.sort((a, b) => {
    if (+a.id > +b.id) return 1
    if (+a.id < +b.id) return -1
  })

  return state
}

/**
 * @inherdit doc
 */
export const checkVariables = (formula, varaibleStor, data, currentState) => {
  const prepareF = (formula) => {
    let fStor = formula.match(/F\d+/g)
    if (!fStor) return formula
    // replase formulas
    if (fStor && fStor.length > 0) {
      fStor.forEach((f) => {
        let fData = currentState.find((item) => item.name === f)
        if (fData) {
          formula = formula.replace(f, fData.value)
        }
      })
    }
    formula = prepareF(formula)

    return formula
  }

  if (!formula) return false
  if (!varaibleStor) return false
  let isChecked = true
  formula = prepareF(formula)

  let variables = formula.match(/X\d+/g)
  let alertedStore = false
  if (!variables) return false

  if (window.application.form_builder) {
    alertedStore = window.application.form_builder.current.errors
  }
  variables.forEach((variable) => {
    let variableInStor = varaibleStor.find(
      (item) => item.name == variable,
    )
    if (!variableInStor || variableInStor.value.length === 0) isChecked = false
    if (variableInStor && variableInStor.value.length !== 0) {
      let regExp = new RegExp(`${variable}([ \+\\-\*\/\)]|$)`, 'g')
      formula = formula.replace(regExp, `${variableInStor.value}$1`)
      formula = formula.replace(',', '.')
    }
  })

  let result = false
  try {
    result = isChecked ? math.eval(formula) : false
  } catch (e) {
    if (alertedStore) {
      if (alertedStore.indexOf(data.name) === -1) {
        bootbox.alert(t('errors.error_in_formula') + data.name)
        alertedStore.push(data.name)
        $('.bootbox').css('zIndex', 10000)
      }
    }
  }
  if (result) {
    const number = parseFloat(result)
    if (number !== math.round(number)) {
      result = number.toFixed(2)
      if (result[result.length - 1] === '0') {
        result = result.slice(0, result.length - 1)
      }
    }
  }

  return result
}

/**
 * @param {String|*} value
 * @return {String|*}
 */
export const stringSanitize = (value) => {
  return typeof value === 'string'
    ? stringNbspSanitize(
      value
        .trim()
        .replace(/&#xFEFF;/gi, '')
    )
    : value
}

/**
 * @param {String} value
 * @return {String}
 */
export const stringNbspSanitize = (value) =>
  value.replace(/&(amp;)?nbsp;/gi, '')
