import { COMPLAINTS_ACTIONS as actions } from '../actions'
import { getNewEntity, findById, sort } from './helpers'

const complaintsReducer = (state = [], { type, payload }) => {
  let newState = [...state]
  let item = {}
  if (payload && payload.id) {
    item = findById(newState, payload.id)
  }

  switch (type) {
    case actions.LOAD:
      return payload || newState

    case actions.ADD :
      let newEntity = getNewEntity(newState, payload)
      newEntity.categories = []
      if (payload.data && payload.data.data.categories) {
        newEntity.categories = payload.data.data.categories
      }
      newState.push(newEntity)

      return newState

    case actions.RESTORE :
      newState.push(payload.item)
      newState = sort(newState)

      return newState

    case actions.DELETE :
      newState = [...state].filter(
        (item) => item.id !== parseInt(payload),
      )

      return newState

    case actions.CHANGE :
      item.categories = payload.data

      return newState

    case actions.UPDATE :
      item.value = payload.value

      return newState

    default:
      return state
  }
}

export default complaintsReducer
