export const CONCLUSIONS = 'conclusions'
export const VARIABLES = 'variables'
export const FIX_LIST = 'fix_list'
export const EXTENSIBLE_LIST = 'ext_list'
export const CALCULATE = 'calculate'
export const COMPLAINTS = 'complaints'
export const DESTINATIONS = 'destinations'
export const DIAGNOSE = 'diagnoses'
export const DIAGNOSES_CONSTRUCTOR = 'diagnoses_constructor'
export const PRINT_AREA = 'print_area'
//difficultu
export const ENTRIES_VARIABLE = 'entries_variable'

export const SEMD_ENTITY = 'semd-entity'
export const SEMD_AREA = 'semd-area'
