export const specSymbolsSanitize = (text, config = {
  noNbsp: false,
  noXfeff: false,
}) => {
  if (!config.noNbsp) {
    text = text.replace(/&nbsp;/gi, '')
  }

  if (!config.noXfeff) {
    text = text.replace(/&#xfeff;/gi, '')
  }

  return text
}
