import Entity from './index'

class Calculate extends Entity {
  edit () {
    this.container.get('calculate').show(this)
  }

  update () {
    this.store.dispatch({
      type: this.actions.UPDATES_DATA,
      payload: this.store.getState().variables,
    })
    const data = this.data.data
    this.elementHtml = isNaN(data) ? '' : data
    this.element.setAttribute('contenteditable', false)
  }

  elementHead (showTypes) {
    let head = false
    if (showTypes) {
      head = this.data.title
    } else {
      head = this.data.name
    }
    this.element.innerHTML = head
  }

  /**
   * inherit
   */
  get switch () {
    return false
  }
}
export default Calculate
