var selectHelper = {
  /**
   * selent on node
   * @param node
   * @returns {boolean}
   */
  selectNode (node) {
    if (!node) return false
    let selection = tinymce.activeEditor.getDoc().getSelection()
    let range = tinymce.activeEditor.getDoc().createRange()
    selection.removeAllRanges()
    range.selectNode(node)
    selection.addRange(range)
  },

  /**
   * selet on node
   * @param range
   */
  selectRange (rangeData, oneNodeFlag = false) {
    var selection = tinymce.activeEditor.getDoc().getSelection()
    var range = tinymce.activeEditor.getDoc().createRange()
    selection.removeAllRanges()
    if (!oneNodeFlag) {
      range.setStart(rangeData.start.node, rangeData.start.offset)
      range.setEnd(rangeData.end.node, rangeData.end.offset)
    } else {
      range.setStart(rangeData.node, rangeData.start)
      range.setEnd(rangeData.node, rangeData.end)
    }
    selection.addRange(range)

    return range
  },

  /**
  * select range
  */
  selectNodeRange (node, startOffset, endOffset = false) {
    if (!endOffset) {
      endOffset = startOffset
    }
    var selection = tinymce.activeEditor.getDoc().getSelection()
    var range = tinymce.activeEditor.getDoc().createRange()
    selection.removeAllRanges()
    range.setStart(node, startOffset)
    range.setEnd(node, endOffset)
    selection.addRange(range)

    return range
  },
}

export default selectHelper
